$container-width: 1199px;

html,
body {
    background: $color-white;
    margin: 0;
    padding: 0;
    color: $color-secondary;
    font-family: $base-font;
    font-size: $base-font-size;
    width: 100%;
    height: 100%;

    strong {
        font-weight: 700;
    }
}

html {
    overflow-y: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .content {
        flex-grow: 1;
    }
}

.container {
    width: 100%;
    max-width: $container-width;
    margin: 0 auto;
    padding: 0 1rem;
}

.alc_content--ellipsis {
    max-width: 100%;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.alc_button {
    cursor: pointer;
    border: 0;
    background: transparent;
    padding: 0.5rem;
    font-family: $base-font;

    &.alc_button--primary {
        background: $color-primary;
        border: 1px solid $color-primary;
        border-radius: 4rem;
        font-size: 1rem;
        line-height: 140%;
        color: $color-white;
        font-weight: 600;
    }

    &.alc_button--secondary {
        background: $color-white;
        border: 1px solid $color-primary;
        border-radius: 4rem;
        font-size: 1rem;
        line-height: 140%;
        color: $color-primary;
        font-weight: 600;
    }

    &.alc_button--link {
        background: none;
        border: none;
        font-size: 1rem;
        line-height: 140%;
        color: $color-primary;
        font-weight: 600;
    }

    &.alc_button--disabled {
        background: var(--ace-color-primary-500, #003595);
        border: 1px solid $color-primary;
        border-radius: 4rem;
        font-size: 1rem;
        line-height: 140%;
        color: #FFF;
        font-weight: 600;
        opacity: 0.3;
        pointer-events: none !important;
    }

    &.alc_button--danger {
        background: $color-white;
        border: 1px solid $color-error;
        border-radius: 4rem;
        font-size: 1rem;
        line-height: 140%;
        color: $color-error;
        font-weight: 600;
    }

    &.alc_button--block {
        display: block;
        width: 100%;
        text-align: center;
    }

    &.alc_button--icon {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            margin-right: .75rem;
            font-size: .875rem;
        }
    }

    &.alc_button--small {        
        font-size: .875rem;
        line-height: 140%;
        padding: 0.25rem 1.5rem;

        span {
            margin-right: .25rem;
            font-size: 1rem;
        }
    }
    
}

.alc_input-group {
    display: flex;
    column-gap: 1.5rem;
    flex-wrap: wrap;

    .alc_input-label {
        width: 100%;
    }
}

.alc_input {

    &.alc_input--size-50 {
        display: block;
        width: 50%;
    }

    &.alc_input--size-60 {
        display: block;
        width: 60%;
    }

    &-label {
        font-size: .75rem;
        line-height: 140%;
        color: $color-primary;
    }

    &-hint {
        font-size: .75rem;
        line-height: 140%;
        color: $color-secondary;
    }

    &-text {
        font-size: .875rem;
        line-height: 140%;
        color: $color-secondary-tint;
        border-radius: 1.5rem;
        border: 1px solid $color-secondary-tint;
        padding: .5rem 1rem;
        width: 100%;
        box-sizing: border-box;
    }

    &-textarea {
        font-family: $base-font;
        font-size: .875rem;
        line-height: 140%;
        color: $color-secondary-tint;
        border-radius: .5rem;
        border: 1px solid $color-secondary-tint;
        padding: 8px 12px 8px 16px;
        width: 100%;
        min-height: 8.375rem;
        resize: vertical;
        box-sizing: border-box;
        display: flex;
    }
    
    &-wrapper--date {
        position: relative;        

        &::after {
            content: '\e900';
            font-family: 'icomoon';
            text-transform: none;
            line-height: 1;
            display: block;
            width: 1rem;
            height: 1rem;
            font-size: 1rem;
            color: $color-secondary-tint;
            position: absolute;
            right: 1rem;
            top: 47%;
            transform: translateY(-50%);
        }

        .alc_input-text {
            padding: .5rem 2.5rem .5rem 1rem;
        }

    }

    &-wrapper--time {
        position: relative;        

        &::after {
            content: '\e908';
            font-family: 'icomoon';
            text-transform: none;
            line-height: 1;
            display: block;
            width: 1rem;
            height: 1rem;
            font-size: 1rem;
            color: $color-secondary-tint;
            position: absolute;
            right: 1rem;
            top: 47%;
            transform: translateY(-50%);
        }

        .alc_input-time {
            padding: .5rem 2.5rem .5rem 1rem;
        }

    }

    &-wrapper--phone {
        position: relative;        

        &::before {
            content: '\e905';
            font-family: 'icomoon';
            text-transform: none;
            line-height: 1;
            display: block;
            width: 1rem;
            height: 1rem;
            font-size: 1rem;
            color: $color-primary;
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
        }

        .alc_input-text {
            padding: .5rem 1rem .5rem 2.5rem;
        }

    }

    &-wrapper--search {
        position: relative;        

        &::after {
            content: '\e909';
            font-family: 'icomoon';
            text-transform: none;
            line-height: 1;
            display: block;
            width: 1rem;
            height: 1rem;
            font-size: 1.25rem;
            color: $color-primary;
            position: absolute;
            right: 1rem;
            top: 47%;
            transform: translateY(-50%);
        }

        &::before {            
            content: '';
            display: block;
            width: 1px;
            height: 1.5rem;
            background: $color-secondary-tint-3;
            position: absolute;
            right: 2.5rem;
            top: 50%;
            transform: translateY(-50%);
        }

        .alc_input-text {
            padding: .5rem 3.5rem .5rem 1rem;
        }

    }

    &-dropdown {
        position: relative;

        &-select {
            font-size: .875rem;
            line-height: 140%;
            color: $color-secondary-tint;
            border-radius: 1.5rem;
            border: 1px solid $color-secondary-tint;
            padding: .5rem 2.5rem .5rem 1rem;   
            width: 100%;              
            appearance: none;  
            background-color: #FFF !important;     
            white-space: nowrap;
            text-overflow: ellipsis;     
        }

        &::before,
        &::after {
            content: '';
            width: 8px;
            height: 2px;
            display: block;
            background: $color-primary;
            position: absolute;
            right: 1rem;
            top: 47%;;
            pointer-events: none;
        }

        &::before {           
            transform: rotate(45deg);
            right: calc(1rem + 5px);
        }

        &::after {            
            transform: rotate(-45deg);
        }
    }    

    &-button {
        font-size: .875rem;
        font-weight: 600;
        line-height: 140%;
        color: $color-primary;
        border-radius: 1.5rem;
        border: 1px solid $color-primary;
        padding: .5rem 1rem;
        cursor: pointer;
        background: transparent;
    }

    &-button--text {
        padding: 0;
        border: 0;
    }

    &-button--text--padding {
        padding: rem(8px) rem(40px);
        border: 0;
    }
    
    &-wrapper--check {
        .alc_input-checkbox {
            display: none;
        }

        .alc_input-checkbox + label {
            margin: 0;
            padding: 0;
            cursor: pointer;
            position: relative;
            font-size: .875rem;
            line-height: 140%;
            color: $color-secondary;

            span {
                margin-left: .5rem;
            }
        }

        .alc_input-checkbox + label:before {
            content: '';
            border: 1px solid $color-secondary-tint;
            border-radius: .25rem;
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            margin-top: -1px;
            vertical-align: middle;
            color: transparent;
            background: $color-white;
            text-align: center;
            line-height: 1.5rem;
        }

        .alc_input-checkbox:checked + label:after {
            content: '';
            position: absolute;
            left: .5rem;
            top: .110rem;
            width: .5rem;
            height: .875rem;
            border: solid $color-primary;
            border-width: 0 2px 2px 0;
            transform: rotate(35deg);
            transition: .2s;
        }

        .alc_input-checkbox + label:active:before,
        .alc_input-checkbox + label:active:after {
            transform: scale(0);
        }

        .alc_input-checkbox:checked + label:before {
            border-color: $color-primary;
            color: $color-primary;
        }

        .alc_input-checkbox:disabled + label:before {
            transform: scale(1);
            opacity: .5;
        }

        .alc_input-checkbox:checked:disabled + label:before {
            transform: scale(1);
            border-color: $color-primary;
            color: $color-primary;
        }

        .alc_input-checkbox:checked:disabled + label:after {
            content: '';
            left: .5rem;
            top: .110rem;
            width: .5rem;
            height: .875rem;
            border: solid $color-primary;
            border-width: 0 2px 2px 0;
            transform: rotate(35deg);
            position: absolute;
            opacity: .5;
        }

    }
}

.alc_form {
    max-width: 61.75rem;
    margin: 0 auto;
    //padding: .5rem;

    @media #{$small} {
        //padding: 1.5rem;
    }

    &-wrapper {
        &--2cols {
            @media #{$small} {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                gap: 2rem;
            }

            .alc_form-wrapper-column {
                @media #{$small} {
                    width: calc(50% - 1rem);
                }
            }
        }

        &-column {
            padding: 0 0.5rem;
            margin-top: rem(24px);

            @media #{$small} {
                padding: 0 4rem 0 2rem;                
            }
        }
    }

    &-header {
        font-size: 1.375rem;
        line-height: 115%;
        font-weight: 300;
        margin-bottom: 2rem;
    }

    &-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 1rem;
        
        &-primary {
            display: flex;
            flex-grow: 1;
            gap: 1rem;

            .alc_button {
                min-width: 10rem;
            }
        }
    
        &-secondary {
            display: flex;
            flex-grow: 0;
            flex-shrink: 0;
            gap: 1rem;

            .alc_button {
                min-width: 10rem;
            }
        }
    }
}

.tag {
    display: flex;
    align-items: center;
    font-size: .75rem;
    line-height: 140%;

    &::before {
        content: '';
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
        margin-right: .5rem;
    }

    &.tag--yes {
        &::before {
            background: $color-success;
        }
    }

    &.tag--no {
        &::before {
            background: $color-secondary-tint-1;
        }
    }

    &.tag--blue {
        &::before {
            background: $color-primary-tint;
        }
    }
}

.alc_separator {
    margin: .5rem 0;
    background: $color-secondary-tint;
    height: 1px;
}

@mixin alcon-toast-snackbar {
    border-radius: 0px 8px 8px 0px !important;
    box-shadow: none !important;
    max-width: inherit !important;
    width: 90vw;
    margin-top: 87px;
}
.success .mdc-snackbar__surface {
    background: #e6f7ec !important;
    border-left: 3px solid #00ae44 !important;
    height: 68px;
    @include alcon-toast-snackbar;
}

.mat-mdc-snack-bar-container{
    width: 289px;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 12px !important;
    margin-top: 80px !important;
}

.dialog-no-margin-top {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        margin-top: 0px !important;
    }
}

.alc_add_patient_dialog {
    width: 838px;
    .mdc-dialog__surface {
        border-radius: 12px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        width: 620px;
    }
    
    @media only screen and (max-width: 767px) {
        width: 343px;
    }
  
}
.align-items-stretch {
    align-items: stretch !important;
}

.alc_form_error {
    border: 1px solid #e6062a !important;
}
.alc_add_room_dialog {
    .mdc-dialog__surface {
        padding: rem(24px) rem(24px) rem(40px) rem(24px);
        max-height: 80vh !important;
    }
    .mdc-dialog__surface::-webkit-scrollbar-track {
        background: #f0f0f0; /* Color of the scrollbar track */
        border-radius: 12px; /* Fully rounded ends of the track */
    }
    .mdc-dialog__surface::-webkit-scrollbar {
        width: rem(6px); /* Width of the scrollbar */
    }
    
    .mdc-dialog__surface::-webkit-scrollbar-thumb{
        background-color: $color-secondary-tint; /* Color of the scrollbar handle */
        border-radius: rem(12px); /* Fully rounded ends of the handle */
    }
}
.error .mdc-snackbar__surface {
    background: #FDE6EA !important;
    border-left: 3px solid #E6062A;
    height: 68px;
    @include alcon-toast-snackbar;
    min-width: 460px !important;
}

.warning .mdc-snackbar__surface {
    background: $color-warning-tint-2 !important;
    border-left: 3px solid $color-warning;
    height: 68px;
    @include alcon-toast-snackbar;
    min-width: 460px !important;
}