@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?6zqpaq');
  src:  url('fonts/icomoon.eot?6zqpaq#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?6zqpaq') format('truetype'),
    url('fonts/icomoon.woff?6zqpaq') format('woff'),
    url('fonts/icomoon.svg?6zqpaq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ico-arrow-down:before {
  content: "\e912";
}
.icon-ico-print:before {
  content: "\e90b";
}
.icon-ico-error:before {
  content: "\e907";
}
.icon-ico-date:before {
  content: "\e900";
}
.icon-ico-download:before {
  content: "\e901";
}
.icon-ico-edit:before {
  content: "\e903";
}
.icon-ico-globe:before {
  content: "\e904";
}
.icon-ico-phone:before {
  content: "\e905";
}
.icon-ico-plus:before {
  content: "\e906";
}
.icon-ico-schedule:before {
  content: "\e908";
}
.icon-ico-search:before {
  content: "\e909";
}
.icon-ico-sort:before {
  content: "\e90a";
}
.icon-ico-user:before {
  content: "\e90d";
}
.icon-ico-view:before {
  content: "\e90e";
}
.icon-ico-arrow-backward:before {
  content: "\e90f";
}
.icon-ico-arrow-forward:before {
  content: "\e910";
}
.icon-ico-arrow-up:before {
  content: "\e911";
}
.icon-ico-cross:before {
  content: "\e913";
}
.icon-filter_list:before {
  content: "\e90c";
}
.icon-check_circle:before {
  content: "\e902";
}
.icon-ico-gear:before {
  content: "\e914";
}
.mat-date-range-input-separator-hidden {
  opacity: 1 !important;
  color: #727780 !important;
}
.surgeries-grid .mat-content, .stats-grid .mat-content {
  display: initial;
}
.mat-expansion-indicator::after {
  color: #003595 !important;
}

.add-options-container .mdc-list-item__primary-text,
.export-options-container .mdc-list-item__primary-text {
  font-weight: 500;
}