
//======================================================================================//
// Fonts
//======================================================================================//

$base-font: 'Open Sans', sans-serif;

$base-font-size: 16px;

$openSans-light: 'OpenSans-Light';
$openSans-bold: 'OpenSans-Bold';
$openSans-semiBold: 'OpenSans-SemiBold';
$openSans-regular: 'OpenSans-Regular';
$openSans-normal: 'OpenSans-Normal';

//======================================================================================//
// Colors
//======================================================================================//

$color-black: #000000;
$color-white: #ffffff;

$color-primary: #003595;
$color-primary-tint: #00aeef;
$color-primary-tint-1: #e5f7fd;
$color-primary-tint-2: #0075CE;

$color-tab-border: #E9E9E9;

$color-secondary: #232223;
$color-secondary-tint: #727780;
$color-secondary-tint-1: #d3d3d3;
$color-secondary-tint-2: #e6ebf4;
$color-secondary-tint-3: #e9e9e9;
$color-secondary-tint-4: #f4f4f4;
$color-secondary-tint-5: #2A4861;

$color-others-tint-1: #6686BF;
$color-others-tint-2:#818181;

$color-error: #e6062a;
$color-error-tint: #bf1f15;
$color-error-tint-1: #ff3c21;
$color-error-tint-2: #ffbdbd;
$color-error-tint-3: #F59BAA;
$color-error-tint-4: #FDE6EA;

$color-success: #00ae44;
$color-success-tint: #66ce8f;
$color-success-tint-1: #e6f7ec;

$color-warning: #FF6B12;
$color-warning-tint: #FF6B12;
$color-warning-tint-1: #FFA671;
$color-warning-tint-2: #FFF0E7;

$color-unassigned: #FF6B12;
$color-unassigned-tint: #FF6B12;
$color-unassigned-tint-1: #FFA671;
$color-unassigned-tint-2: #FFF0E7;
$color-account-background: #E5F5FF;
$color-tile-shadow: #00359526;
$color-neutral-black: #393939;

$text-color1: #4F4E4F;
$text-color2: #232323;
$text-color3: #FFFFFF0F;
$text-color4: #FFFFFF47;

$dark-theme-body-background: #0F1C29;
$dark-theme-secondary-text: #A7B6CA;
$dark-theme-light-text: #DFDFDF;
$dark-theme-border: #2C3F52;
$dark-theme-pop-up-background:#2A4861;

$light-theme-text1: #161B25;
$light-theme-text2: #666;

//======================================================================================//
// Media Queries
//======================================================================================//

$tiny-screen: 568px !default;
$small-screen: 768px !default;
$medium-screen: 1024px !default;
$large-screen: 1366px !default;

$screen: "only screen" !default;
$tiny: "only screen and (min-width: #{$tiny-screen})" !default;
$small: "only screen and (min-width: #{$small-screen})" !default;
$medium: "only screen and (min-width: #{$medium-screen})" !default;
$large: "only screen and (min-width: #{$large-screen})" !default;

$width-max-mobile: 767;
$width-min-tablet: 768;
$width-max-tablet: 992;
$width-min-desktop: 993;

$ore-screen-container-width: 1680px;

$mobile-device: 'only screen and (max-width: #{$width-max-mobile}px)';
$tablet-device: 'only screen and (min-width: #{$width-min-tablet}px) and (max-width: #{$width-max-tablet}px)';
$desktop-device: 'only screen and (min-width: #{$width-min-desktop}px)';

@use 'sass:math';

@function rem($size) {
  @return math.div($size, 16px) + rem;
}

@mixin fontFamily($family: $base-font, $size: 14px, $height: 20px, $weight: 400) {
    font-family: $family;
    font-size: rem($size);
    line-height: $height;
    font-weight: $weight;
  }
