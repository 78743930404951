@import "../theme-settings";

.alc_detail {
    margin: 1.5rem 0;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    .primary-header {
        font-size: 1.75rem;
        line-height: 110%;
    }

    .secondary-header {
        font-size: 1.375rem;
        line-height: 120%;
        font-weight: 600;
        padding: .75rem;
        background: $color-secondary-tint-2;
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
    }

    &-content {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        padding: 0 .75rem;
    }

    &-row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }    

    .alc_detail-row--4 {
        column-gap: 1rem;

        .alc_detail-item {
            flex-grow: 1;
            flex-shrink: 0;
            max-width: calc(25% - .33rem);
        }
    }

    .alc_detail-row--3 {
        column-gap: 1rem;

        .alc_detail-item {
            flex-grow: 1;
            flex-shrink: 0;
            max-width: calc(33% - .5rem);
        }
    }

    .alc_detail-item {
        font-size: 1rem;
        line-height: 140%;
        font-weight: 600;
        display: flex;
        align-items: center;
        column-gap: .5rem;
        flex-wrap: wrap;

        .tag {
            font-size: 1rem;
            line-height: 140%;
            font-weight: 600;

            &::before {
                width: .625rem;
                height: .625rem;
            }
        }

        > p {
            display: block;
            width: 100%;
            font-size: .875rem;
            line-height: 140%;

            strong {
                font-size: 1rem;
                line-height: 140%;
            }
        }
    }

    .alc_grid {
        .container {
            padding: 0;
        }

        &-columns {
            background: $color-white !important;;
        }

        &-column {
            font-size: 1rem !important;
        }

        .status {
            &::before {
                content: '';
                width: .75rem;
                height: .75rem;
                border-radius: 50%;
                display: inline-flex;
                margin-right: .325rem;
            }

            &.status--completed {
                &::before {
                    background: $color-success;
                }
            }

            &.status--pending {
                &::before {
                    background: $color-warning-tint;
                }
            }

            &.status--inactive {
                &::before {
                    background: $color-error-tint;
                }
            }
        }
    }
}