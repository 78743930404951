//======================================================================================//
// Global Icons
//======================================================================================//

@import "./assets/style.css";

//======================================================================================//
// Global theme settings
//======================================================================================//

@import "./app/components/theme-settings";

//======================================================================================//
// Global components
//======================================================================================//

@import "./app/components/base/base.component";
@import "./app/components/detail/detail.component";
@import "./app/components/sort-filter/sort-filter.component";

@import "./assets/styles/material-styles.scss";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", Roboto, "Helvetica Neue", sans-serif !important;
}

.success-msg,
.error-msg {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.success-msg {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.error-msg {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.pop-head {
  display: flex;
  justify-content: space-between;
}

.blurred {
  backdrop-filter: blur(10px);
  transition: none;
}

.letf-margin {
  margin-left: 15px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  /* Width of the switch */
  height: 28px;
  /* Height of the switch */
}

/* The label text styling */
.switch-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  margin-right: 0.5rem;
  user-select: none;
  /* Prevent text selection */
  pointer-events: none;
  width: max-content;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #818181;
  /* Switch OFF color */
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  /* Height of the slider circle */
  width: 22px;
  /* Width of the slider circle */
  left: 5px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #2196F3;
  /* Switch ON color (blue) */
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Color for when the switch is ON */
input:checked+.slider {
  background-color: #00AE44;
  /* Green color */
}

.alc_multi-select .multiselect-dropdown .dropdown-btn {
  border-radius: 24px !important;

  .dropdown-multiselect__caret {
    padding: 0px 6px !important;
    transition: none !important;
    top: calc(50% - 38px) !important;
    line-height: unset !important;

    &::before {
      border: solid $color-primary !important;
      border-width: 0 2px 2px 0 !important;
      transform: rotate(45deg) !important;
      display: inline-block;
      padding: 3px;
      -webkit-transform: rotate(45deg) !important;
    }
  }

  .dropdown-multiselect--active .dropdown-multiselect__caret {
    top: calc(50% - 0px) !important;
  }
}

/* Override the styles for selected items */
.alc_multi-select .multiselect-dropdown .selected-item {
  background: transparent !important;
  border: none !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
  color: $color-secondary !important;

  &:hover {
    box-shadow: none !important;
  }
}

/* Override the styles for selected items */
.alc_input .alc_multi-select .multiselect-dropdown .selected-item a {
  display: none;
}

/* Hide the close icon for the selected items */
.alc_input .alc_multi-select .multiselect-dropdown .selected-item div {
  display: none;
}
.alc_form {
  margin: 0;
}

/* Override the styles for the badges */
.alc_input .alc_multi-select .multiselect-dropdown .selected-badge {
  padding: 0 2px;
  background: none;
  box-shadow: none;
}

.alc_input .alc_multi-select .multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked+div:before {
  background: #003595;
}

.alc_input .alc_multi-select .multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]+div:before {
  color: #727780;
  width: 18px;
  height: 18px;
  border: 1px solid #727780;
  border-radius: 4px;
}

.alc_input .alc_multi-select .multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]+div:after {
  left: 3px;
  width: 11px;
  height: 4px;
}

.alc_input .alc_multi-select .multiselect-dropdown .dropdown-list {
  border: none;
  height: 280px;
  border-radius: 20px;
  box-shadow: 0px 5px 20px 0px hsla(219, 100%, 29%, 0.15);

  ul.item2 {
    max-height: 260px !important;

    li {
      padding: 10px 14px !important;
    }
  }
}

.mat-datepicker-content .mat-calendar {
  width: 284px !important;
  height: 399px !important;
}

.mat-datepicker-content {
  border-radius: 12px !important;
  margin-top: 4px;
}

.mat-calendar-header {
  padding: 12px 16px;
  border-radius: 12px 12px 0px 0px;
  background: var(--ace-color-primary-500, #003595);
}

.mat-mdc-button .mdc-button__label {
  z-index: 1;
  color: var(--ace-color-neutral-100, #FFF);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.mat-calendar-arrow {
  fill: var(--ace-color-neutral-100, #FFF);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  color: var(--ace-color-neutral-100, #FFF);
}

.mat-calendar-content {
  padding-top: rem(16px) !important;
}

.mat-calendar-body-in-range .mat-calendar-body-cell-content {
  background-color: #cceffc;
  border: 10px solid #cceffc;
  border-left-width: 0;
  border-radius: 0;
  border-right-width: 0;
  color: #232223;
  width: 100%;
}

.mat-calendar-body-range-end>.mat-calendar-body-selected {
  background-color: #003595;
  border: 3px solid #003595;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 50%;
  border-left-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 50%;
  color: #ffff;
}

.mat-calendar-body-range-start>.mat-calendar-body-selected {
  background-color: #003595;
  border: 3px solid #003595;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 0;
  border-right-width: 0;
  border-top-left-radius: 50%;
  border-top-right-radius: 0;
  color: #ffff;
}

.mat-calendar-body-preview-start>.mat-calendar-body-selected {
  background-color: #003595;
  border: 3px solid #003595;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 0;
  border-right-width: 0;
  border-top-left-radius: 50%;
  border-top-right-radius: 0;
  color: #ffff;
  width: 100%;
}

.mat-calendar-body-in-preview:not(.mat-calendar-body-preview-start):not(.mat-calendar-body-preview-end) .mat-calendar-body-cell-content {
  background-color: #cceffc !important;
  border: 10px solid #cceffc;
  border-radius: 0;
  width: 100%;
  border-left-width: 0;
  border-right-width: 0;
  color: #232223;
}

.mat-calendar-body-preview-end>.mat-calendar-body-cell-content {
  background-color: #003595 !important;
  border: 3px solid #003595;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 50%;
  border-left-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 50%;
  color: #ffff;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #cceffc;
  border: 0px;
}

.add-options-panel,
.admin-ux-panel,
.export-options-panel {
  position: absolute !important;
  top: 0% !important;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 12px !important;
  }
}

.dialog-backdrop {
  background-color: rgba(0, 53, 149, 0.64);
}

.dialog-backdrop-dark {
  background-color: rgba(0, 15, 41, 0.56);
}

.no-surgeries-msg {
  margin-top: 1rem !important;

  .header,
  .content {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
  }
}

.export-msg-success {
  .mdc-snackbar__label {
    color: #232223 !important;
  }

  .mdc-snackbar__surface {
    background-color: #E6F7EC !important;
    border-left: 3px solid #00AE44;
    position: relative !important;
    top: 4.5rem !important;
    padding: 0 !important;
    margin: 0 !important;
    min-width: 460px !important;
  }
}

.export-msg-error {
  .mdc-snackbar__label {
    color: #232223 !important;
  }
  .mdc-snackbar__surface {
    background-color: #FDE6EA !important;
    border-left: 3px solid #E6062A;
    position: relative !important;
    top: 4.5rem !important;
    padding: 0 !important;
    margin: 0 !important;
    min-width: 460px !important;
  }
}
@media #{$mobile-device}{
  .export-msg-success, .export-msg-error {
    .mdc-snackbar__surface {
      min-width:350px !important;
    }
  }

}

// media queries
@media (max-width: 768px) {

  html,
  body {
    overflow-x: hidden;
  }

  .no-surgeries-msg {
    .header {
      font-size: 28px;
      font-weight: 300;
      line-height: 32px;
    }

    .content {
      font-weight: 400;
    }
  }

  .add-options-panel,
  .admin-ux-panel,
  .export-options-panel {
    width: 95%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .no-surgeries-msg {
    .header {
      font-size: 28px;
      font-weight: 300;
      line-height: 32px;
    }

    .content {
      font-weight: 400;
    }
  }

  .add-options-panel,
  .admin-ux-panel,
  .export-options-panel {
    width: 70%;
  }
}

@media (min-width: 992px) {

  .add-options-panel,
  .export-options-panel {
    width: 40%;
  }
}

.alc-upload-content {
  background-image: linear-gradient(to right, $color-primary 50%, transparent 50%), linear-gradient(to right, $color-primary 50%, transparent 50%), linear-gradient(to bottom, $color-primary 50%, transparent 50%), linear-gradient(to bottom, $color-primary 50%, transparent 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 20px 1.5px, 20px 1.5px, 1.5px 20px, 1.5px 20px;
  opacity: 0.8;
  border-radius: 8px;
}

.patient-ready-tooltip.mat-mdc-tooltip{
  white-space: pre !important;
  .mdc-tooltip__surface{
    text-align: left !important;
  }
}

.comment-tooltip.mat-mdc-tooltip{
    white-space: pre-line !important;
    .mdc-tooltip__surface{
      text-align: center !important;
    }
}
.mat-mdc-tooltip-panel-above{
  .arrow-tooltip.mat-mdc-tooltip{
      overflow: visible !important;
      &:after {
        content: " ";
        position: absolute;
        top: 100%;
        z-index: 1;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 7px 0 7px;
        border-color: $text-color1 transparent transparent transparent;
        left: calc(50% - 7px);
      }
  }
}
.mat-mdc-tooltip-panel-below{
  .arrow-tooltip.mat-mdc-tooltip{
    overflow: visible !important;
    &:after {
      content: " ";
      position: absolute;
      top: -5px;
      z-index: 1;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0px 7px 5px 7px;
      border-color: transparent transparent $text-color1 transparent;
      left: calc(50% - 6px);
    }
}
}

.recent-changes-tooltip.mat-mdc-tooltip {
  white-space: pre-line !important;
  .mdc-tooltip__surface{
    text-align: left !important;
  }
}

.margin-bottom-0{
  margin-bottom: 0 !important;
}
.hide-overflow{
  overflow: hidden;
}

.surgeries-grid {
  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: 0px 5px 20px rgba(0, 53, 149, 0.15);

   }
   @media only screen and (min-width: $small-screen) {
       .mat-expansion-panel-body{
           padding: 0;
       }
       .mat-expansion-panel.mat-expanded {
        padding-bottom: 0!important;
       }
   }
}

.alcon-buot-container{
  .mat-expansion-panel-body{
    padding: 0;
  }
  .mat-expansion-panel-header {
    padding: 0 rem(12px);
  }
}

.mat-calendar{
  font-family: $base-font;
  color: $color-secondary;
}
.more-menu{
  &.mat-mdc-menu-panel.mat-mdc-menu-panel {
    border-radius: rem(12px);
    box-shadow: 0px 5px 20px 0px #00359526;
  }
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-progress{
  cursor: progress;
}

.alcon-show-in-mobile-only{
  display: none;
}
.alcon-hide-in-mobile-only{
  display: unset;
}
.alcon-show-in-desktop-only{
  display: unset;
}
.alcon-hide-in-desktop-only{
  display: none;
}

@media #{$mobile-device} {
  .alcon-show-in-mobile-only{
    display: unset;
  }
  .alcon-hide-in-mobile-only{
    display: none;
  }
  .alcon-show-in-desktop-only{
    display: none;
  }
  .alcon-hide-in-desktop-only{
    display: unset;
  }
}

@media #{$tablet-device} {
  .alcon-show-in-mobile-only{
    display: none;
  }
  .alcon-hide-in-mobile-only{
    display: unset;
  }
  .alcon-show-in-desktop-only{
    display: none;
  }
  .alcon-hide-in-desktop-only{
    display: unset;
  }
}

.upload-status {
  display: inline-block;
  font-size: rem(12px);
  color: $color-neutral-black;
  text-align: center;
  border-radius: rem(24px);
  padding: rem(4px) rem(10px);
  min-width: rem(100px);
  &.upload-status-in-progress {
      border: 1px solid #66CEF5;
      background-color: #E5F7FD;
    }
  &.upload-status-pending {
      border: 1px solid $color-secondary-tint;
      background-color: $color-tab-border;
    }

  &.upload-status-uploaded {
      border: 1px solid #66CE8F;
      background-color: #E6F7EC;
    }

}
.alcon-common-scroll::-webkit-scrollbar , .dialog-overflow-scroll .mat-mdc-dialog-surface::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.alcon-common-scroll::-webkit-scrollbar-thumb, .dialog-overflow-scroll .mat-mdc-dialog-surface::-webkit-scrollbar-thumb {
  background: $color-secondary-tint;
  border-radius: 5px;
}
.history-log-dialog, .alcon-common-dialog {
  width: 343px !important;
  .mat-mdc-dialog-container .mat-mdc-dialog-content {
    padding: 0;
  }
  .mat-mdc-dialog-content {
    max-height: initial;
  }
  .mat-typography p {
      margin: 0;
  }
  max-width: 95% !important;
  .mdc-dialog__container, mat-dialog-container {
    max-width: 100% !important;
  }
  @media #{$tablet-device} {
    width: 620px !important;
  }
  @media #{$desktop-device} {
    width: 838px !important;
  }
}

.alc-sm-spinner {
  border: 2px solid $color-secondary-tint-4;
  border-top: 2px solid $color-primary;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.comment-yes svg {
  transform: scale(1.5);
  position: relative;
  top: 14%;
}

.alcon-navigation-link{
  font-weight: 600;
  color: $color-primary;
  text-decoration: none;
  border-bottom: 1px solid $color-primary;
  span.name{
      text-decoration: underline;
      text-underline-position: under;

      @media #{$tablet-device}{
          text-decoration: none;
      }
  }
}

.alc_input {
  &-wrapper--icon {
    position: relative;
    .clear-btn {
      text-transform: none;
      line-height: 1;
      display: block;
      width: 1rem;
      height: 1rem;
      font-size: 1rem;
      color: $color-primary;
      position: absolute;
      right: 1rem;
      top: 47%;
      transform: translateY(-50%);
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      outline: none;
      mat-icon {
        font-size: 20px;
      }
    }
    .error-btn{
      right: 2.2rem;
      color: $color-error;
    }
  }
}


button[disabled] {
  opacity: 0.3;
  pointer-events: none;
  cursor: none;
}

.alcon-upload-in-progress {
  .alcon-fu-select-button {
    opacity: 0.3;
    pointer-events: none;
  }
}

.alcon-pdf-viewer{
  .ng2-pdf-viewer-container{
    overflow: visible !important;
    //position: relative !important;
    .pdfViewer{
      display: flex;
      flex-direction: column;
      .page{
        box-shadow: 0px 5px 20px 0px $color-tile-shadow;
        margin-top: rem(12px) !important;
        border-radius: 12px;
        border: 1px solid transparent !important;
        overflow: hidden !important;
      }
    }
  }
}

// Common styles for the patient name truncate and hyphenate

.name-truncate-in-three-lines {
  display: -webkit-box;
  -webkit-line-clamp: var(--line-clamp, 3);
  line-clamp: var(--line-clamp, 3);
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  -webkit-hyphens: auto !important;
  hyphens: auto !important;
  text-align: var(--align, left);
  --is-single-line: 1 - Clamp(0, Calc(var(--line-clamp) - 1), var(--line-clamp));
}

/* Loader CSS */
.app-loader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  pointer-events: all;

  .app-loader-header{
    background: #003595;
    height: 64px;
    padding: 0 0 0 48px;
    gap: 31px;
    display: flex;
    align-items: center;
    top:0;
    position: absolute;
    width: 100%;
   }
   @media screen and (min-width: 768px) and (max-width: 1023.98px) {
    .app-loader-header {
        padding-left: 24px;
    }
   }
  @media screen and (max-width: 767.98px) {
      .app-loader-header {
        padding-left: 16px;
    }
  }
   .app-loader-footer{
    background: #003595;
    height: 100px;
    padding: 0 0 0 48px;
    gap: 31px;
    display: flex;
    align-items: center;
    bottom: 0;
    position: absolute;
    width: 100%;
   }
   .overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
    pointer-events: all;
   }
    /* Loader styles */
    .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      animation: spin 2s linear infinite;
      z-index: 100; /* Ensures that the loader appears above the overlay */
    }
  }
  

.admin-schedular-more-menu {
  &.mat-mdc-menu-panel.mat-mdc-menu-panel {
    border-radius: rem(20px);
    box-shadow: 0px 5px 20px 0px #00359526;
  }
  &.mat-mdc-menu-panel {
    min-width: 174px;
  }
}

.alcon-ore-container {
  .alcon-ore-last-updated-action{
      .alcon-or-action-details {
        .alcon-or-action-label, .alcon-timeout-field {
          color: $color-white !important;
          font-weight: 600 !important;
        }
    }
  }
  &.alcon-ore-container-light {
    .alcon-or-action-details {
      .mat-icon {
        color: $color-secondary-tint !important;
      }
    }
    .alcon-ore-last-updated-action {
      .alcon-or-action-details {
        border-radius: rem(12px);
        background: $color-primary;
        .mat-icon {
          color: $color-white !important;
        }
      }
    }
    .alcon-ore-active-action {
      .alcon-or-action-details {
        border-radius: rem(12px);
        background: $color-primary;
      }
    }
    .alcon-edit-progress-ore {
      .alcon-or-action-details {
        background: #E2EFF9;
        .mat-icon {
          color: $color-white !important;
        }
        .alcon-timeout-field {
          color: $color-white !important;
          font-weight: 600 !important;
        }
      }  
    } 
  }
  
  .alcon-edit-progress-ore {
    .alcon-or-action-details {
      background: #A7B6CA4D;
      border-radius: rem(12px);
    }  
  } 
  .alcon-ore-active-action {
    .alcon-or-action-details {
      background: #185181;
      border-radius: rem(12px);
    }
    .alcon-or-action-container {
      border-top: 0;
    }
  }
  .alcon-ore-active-action + .alcon-ore-timeout-action {
    .alcon-or-action-container {
      border-top: 0;
    }
  }
  .alcon-ore-timeout-action:first-child {
    .alcon-or-action-container {
      border-top: 0;
    }
  }
}
.searchable-dropdown{
  .mat-mdc-form-field {
    width: 90%;
    @media #{$mobile-device}{
      width: 100%;
    }
    @media #{$tablet-device}{
      width: 100%;
    }
  }
  .mat-mdc-form-field-error-wrapper{
    padding: 0 0 0 6px;
    @media #{$mobile-device}{
      padding: 0 0 0 12px;
    }
    @media #{$tablet-device}{
      width: 100%;
      padding: 0 0 0 16px;
    }
  }
  .mdc-text-field--invalid{
    border: 1px solid $color-error;
  }
}
.alcon-surgery-form-add-container .NgxEditor__Content{
  height: 300px;
  overflow: auto;
}
