@use '@angular/material' as mat;
@use "./typography.config.scss" as config;
@import "../../app/components/theme-settings.scss";

@include mat.all-component-themes(config.$cc-light-theme);

  .cdk-overlay-pane {
    .alc_list-select-container {
      border-radius: rem(24px) !important;
      max-height: 250px !important;
      margin-top: 8px !important;
      margin-bottom: 8px !important;
      .mat-mdc-option{
        font-size: rem(14px);
      }
      .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal,
      .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal {
        display: none;
      }
    }
    .alc_list-searchable-select-container {
      &.mat-mdc-select-panel{
        overflow: hidden;
      }
    }
  }

  .alc_mat-form-field-outlined{
    width: 100%;
    .mat-mdc-form-field-infix {
        min-height: 37px;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .mdc-line-ripple{
        display: none;
      }
      .mat-mdc-text-field-wrapper{
        background: none;
        border-radius: 1.5rem;
        border: 1px solid #727780;
      }
      .mat-mdc-form-field-focus-overlay{
        display: none;
      }
      .mat-mdc-select-arrow-wrapper {
        background-image: url('../images/down-arrow.svg');
        background-position: 0px 2px;
        .mat-mdc-select-arrow {
          width: 1.25rem;
          svg {
            visibility: hidden;
          }
        }
      }
      .mat-mdc-select-disabled{
        .mat-mdc-select-arrow-wrapper {
          background-image: url('../images/down-arrow-disabled.svg');
          background-position: 0px 2px;
          .mat-mdc-select-arrow {
            width: 1.25rem;
            svg {
              visibility: hidden;
            }
          }
        }
      }
      .mat-mdc-select-min-line{
        font-size: rem(14px);
      }
      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: $color-white;
      }
      .mdc-text-field--focused{
        border: 1px solid $color-primary !important;
      }
  }
  .alc_mat-form-field-outlined--error{
    .mat-mdc-text-field-wrapper{
        border: 1px solid #e6062a !important;
      }
  }
  .alc_mat-form-field-outlined--no-error-slot{
    .mat-mdc-form-field-subscript-wrapper{
      display: none;
    }
  }

  .alc_mat-form-field-outlined--disabled{
    .mat-mdc-text-field-wrapper{
      background-color: $color-secondary-tint-4;
    }
  }
  .alc_mat-form-field-outlined-error-overflow{
    .mat-mdc-form-field-subscript-wrapper{
      display: flex;
      .mat-mdc-form-field-error-wrapper{
        padding: 0px 3px;
        position: static;
        .mat-mdc-form-field-error{
          text-align: center;
        }
      }
    }
  }

  .alc_mat-form-field-container--disabled{
    .alc_input-label{
      color: $text-color1 !important;
    }
    .alc_mat-form-field-outlined{
      .mat-mdc-text-field-wrapper{
        background-color: $color-secondary-tint-4 !important;
      }
      .mat-mdc-select-arrow-wrapper {
        -webkit-filter: grayscale(100%);
         filter: grayscale(100%);
      }
    }
  }
  .alc-surgeon-create {
    .mat-mdc-form-field-subscript-wrapper:has(.mat-mdc-form-field-error-wrapper){
      height: auto;
  
    }
    .mat-mdc-form-field-subscript-wrapper { 
      height:0;
    }
    .mat-mdc-form-field-error {
      font-size: 14px;
      color: $color-error;
    }
  }


  .alc_popup-full-max-width{
    max-width: 100vw !important;
  }

  .alc_exit-or-popup{
    max-width: 80vw !important;
    .alc-custom-dialog-content-footer{
     justify-content: center;
     align-items: flex-start; 
     button {
     width: 25%;
     flex: none !important;
     }
    }
  }
 .alc_or_popup_dark_mode{
  .alc-custom-dialog-container{
    background:$dark-theme-pop-up-background;
    color:$color-white;
    .alc-custom-dialog-message p {
      font-weight: 600;
    }
    .alc-custom-dialog-close-icon{
      color:$color-white !important
    }
    .alc_button.alc_button--secondary {
      color: $color-white;
      background: $dark-theme-pop-up-background;
      border: 1px solid $color-white;
    }
    .alc_button.alc_button--primary {
      color: $dark-theme-pop-up-background;
      background: $color-white;
      border: 1px solid $color-white;
    }
  }
  }

  .alc_or_popup_light_mode{
    .alc-custom-dialog-container{
      background:$color-white;
      color:$text-color2;
      .alc-custom-dialog-message p {
        font-weight: 600;
      }
    }
    }

  .alc_header_account-menu{
    border-radius: rem(24px) !important;
    max-height: 250px !important;
    .mat-mdc-menu-item{
      padding-top: rem(4px);
      padding-bottom: rem(4px);
    }
    .mdc-list-item__primary-text{
      font-size: rem(14px) !important;
    }
  }

  .alcon-tab{
    margin: 0 -20px;
    max-width: initial!important;
    .mat-mdc-tab .mdc-tab__text-label, 
    .mat-mdc-tab-link .mdc-tab__text-label{
      color: $color-secondary;
      letter-spacing: normal;
    }
    .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, 
    .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label{
      color: $color-primary !important;
      font-weight: 600 !important;
    }
    .mdc-tab-indicator__content--underline{
      border-color: $color-primary !important;
    }
    .mdc-tab{
      padding-left: 14px;
      padding-right: 14px;
    }
    .mat-mdc-tab-label-container{
      border-bottom: 0.5px solid $color-tab-border
    }
    .mat-mdc-tab-body-content, .mat-mdc-tab-header{
      padding: 0 20px;
    }
    .mat-mdc-tab-body-content{
      padding-bottom: 20px;
    }  
  
  }

  .hide-header{ 
    .mat-horizontal-stepper-header-container{
    display: none;
   } 
   .alc_form-wrapper-column{
      margin-top:0px;
   }
}
  .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item{
    max-width: none!important;
    margin-bottom: 4px;
  }

  .alcon-date-picker{
    .mat-calendar-arrow {
      fill: $color-white;
    }
    
    .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base,.mat-datepicker-content .mat-calendar-previous-button:not(.mat-mdc-button-disabled),
    .mat-datepicker-content .mat-calendar-next-button:not(.mat-mdc-button-disabled) {
      color: $color-white;
    }
}

.alcon-menu-panel-class{
  margin-top: rem(-35px);
  margin-right: rem(20px);
  min-width: 200px !important;
  .mat-mdc-menu-item .mat-icon {
    margin-right: rem(4px);
    width: 16px; 
    height: 20px;
    margin-bottom: rem(5px);
  }
  .mat-mdc-menu-content{
    padding: 0;
  }
}

@media #{$mobile-device}{
  .alcon-menu-panel-class{
    margin-top: rem(-35px);
    margin-left: rem(25px);
  }
}
.alc_list-select-container{
  .alcon-surgeon-search{
    margin: 8px;
    .search-close-icon{
        position: absolute;
        right: 3rem;
        width: 20px;
        color: $color-primary;
        height: 20px;
        font-size: 20px;
        top: 9px;
        cursor: pointer;
    }
}

  .alcon-select-group{
      padding: 12px 16px;
      @include fontFamily($base-font, 12px, 17px, 600);
      display: flex;
      justify-content: space-between;
  }
  .mat-pseudo-checkbox-full{
    border: 1px solid $color-secondary-tint;
    border-radius: 4px;
  }
  .mat-mdc-option.mat-pseudo-checkbox {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .alcon-surgeon-name{
    line-height: 20px;
    color: $color-secondary;
  }
  .mat-mdc-option.mdc-list-item{
    align-items: flex-start;
  }
  .alcon-surgeon-number{
    color: $color-secondary-tint;
    line-height: 20px;
  }
  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
    background-color: $color-primary;
  }
  .mat-mdc-option{
    padding: 12px 16px;
  }
}
/* Custom styles for disabled mat-select trigger */
.mat-form-field-disabled {
  .mdc-text-field--filled.mdc-text-field--disabled{
    color:$text-color1;
    background-color: $color-secondary-tint-4;
    border-color:$color-secondary-tint;
  }
  .mat-select-arrow{
    color: $text-color1;
  }
}

.alcon-radio-button{
  .mdc-radio__outer-circle{
    border-color: $color-secondary-tint !important;
  }
  .mdc-radio__inner-circle{
    border-color: $color-white !important;
  }
  .mat-mdc-radio-checked{
    .mdc-radio__outer-circle{
      border: solid 6px $color-primary !important;
    }
  }
  .mdc-label{
    white-space: nowrap;
  }
}

.alcon-accordion{
  mat-expansion-panel {
    mat-expansion-panel-header {
      height: auto!important; 
    }
  }
  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover{
    background: none;
  }
}

.alcon-supplies-tile {
  .mat-grid-tile-content {
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
}

.mat-calendar-next-button::after, .mat-calendar-previous-button::after{
  color: $color-white !important;
}

.mat-calendar-arrow {
  fill: $color-white !important;
}

.alcon-checkbox-hide-ripple {
  .mdc-checkbox--disabled {
    .mdc-checkbox__background {
        background-color: $color-secondary-tint-3 !important;
        border-color: $color-secondary-tint !important;
    }
  }
  .mdc-checkbox__ripple {
      display: none;
  }
}

@media #{$mobile-device} {
  .add-options-container {
      .mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text{
          text-overflow: unset;
          white-space: normal;
          overflow: auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
      }
  }
  .alcon-complete-surgeries-container {
    .alcon-complete-surgeries-list {
      thead {
          display: none;
      }
      tbody {
          display: block;
      }
    }
  }

}

.alcon-add-surgery-stepper{
  flex-grow: 1;
  .mat-horizontal-stepper-wrapper{
    height: 100%;
    .mat-horizontal-content-container{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      .mat-horizontal-stepper-content{
        flex: 1 1 100%;
      }
      .mat-horizontal-stepper-content-inactive{
        display: none;
      }
    }
  }
}

.alc-disabled-field{
  opacity: 0.5;
  pointer-events: none;
  cursor: none;
  input{
    background:$color-secondary-tint-1
  };
  .mat-mdc-select-arrow-wrapper {
    background-image: url('../images/down-arrow-disabled.svg');
    background-position: 0px 2px;
    .mat-mdc-select-arrow {
      width: 1.25rem;
      svg {
        visibility: hidden;
      }
    }
  }
  .alc_mat-form-field-outlined{
    .mdc-text-field--filled:not(.mdc-text-field--disabled){
     background:$color-secondary-tint-1
    };
   
}
}

.alc-disabled-field-dark-opacity{
  opacity: 0.8;
  pointer-events: none;
  cursor: none;
  input{
    background:$color-secondary-tint-4
  };
  .mat-mdc-select-arrow-wrapper {
    background-image: url('../images/down-arrow-disabled.svg');
    background-position: 0px 2px;
    .mat-mdc-select-arrow {
      width: 1.25rem;
      svg {
        visibility: hidden;
      }
    }
  }
 .alc_mat-form-field-outlined{
  .mdc-text-field--filled:not(.mdc-text-field--disabled){
   background:$color-secondary-tint-4
  };
}
}
@media (max-width: $medium-screen) {
  .alc_exit-or-popup{
      .alc-custom-dialog-content-footer{
        button {
          width: 100%;
          }
      }
  }
}

.alcon-snackbar-width {
  .mdc-snackbar__surface {
    min-width: max-content !important;
  }
}

.alcon-surgery-verification-sign {
  .mdc-checkbox__checkmark {
    color: $color-secondary-tint !important;
  }
}

@media #{$desktop-device,$tablet-device}{
  .alc-surgery-filter-container {
    .mat-grid-tile-content {
      align-items: unset!important;
    }
  }
}

.iol-model-component-container{
  .mat-expansion-panel-header {
    display: flex;
    align-items: center;
    padding-left: rem(8px);
    padding-right: 0;
  }

  .mat-expansion-panel-header, .mat-expansion-panel-header.mat-expanded{
    height: auto;
  }
  .mat-expansion-panel-header .mat-content {
    order: 2; 
    margin-left: rem(8px);
    justify-content: space-between;
    padding: 0px 0;
    .mat-expansion-panel-header-title, .mat-expansion-panel-header-description{
      flex-grow: initial;
      flex-basis: initial;
      margin-right: 0;
      font-weight: 600;
    }
  }

  .mat-expansion-panel-header .mat-expansion-indicator {
    order: 1; 
    margin-right: rem(8px); 
    height: 25px;
  }
  .mat-expansion-panel{
    box-shadow: none!important;
    border: none;
  }    

 .mat-expansion-panel.outer-expansion{
    border-bottom: 1px solid $color-tab-border;
    box-shadow: none!important;
 }
 .inner-expansion .mat-expansion-panel-body{
  padding-left: rem(16px);
 }
 .mat-expansion-panel.outer-expansion:last-child{
   border-bottom: none;
 }
 .mat-expansion-panel-body{
  padding-right: 0;
  padding-bottom: 0;
 }
 .mat-expansion-panel-spacing {
    margin: 0;
  }
}
 

.alcon-check-box {
  .mdc-checkbox {
    .mdc-checkbox__native-control:enabled {
      &.checked,
      &:focus:checked,
      &:indeterminate,
      &[data-indeterminate='true'],
      &:checked,
      &:hover:checked,
      &:not(:disabled):active:checked {
        ~ .mdc-checkbox__background {
          background-color: $color-primary; 
          border-color: $color-primary;
          border-radius: 4px;
        }
      }
    }

    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background{
      border: 1px solid $color-secondary-tint;
      border-radius: 4px;
    }

    .mat-ripple-element, .mdc-checkbox__ripple{
         display: none !important;
       }
  }
 
}

.alcon-sif-container {
  .mat-mdc-form-field , .mat-mdc-form-field-type-mat-select, app-alcon-searchable-dropdown {
    width: 100% !important;
  }
}

.iol-details-section {
  .mdc-data-table__cell, .mdc-data-table__header-cell {
    padding: 0 rem(8px) 0 rem(8px);
  }
}

.readonly-select.mat-mdc-form-field-type-mat-select .mat-mdc-text-field-wrapper {
  background-color: $color-secondary-tint-4;
  pointer-events: none;
  cursor: text;
}







