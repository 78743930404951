.alc_sort-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--sort {
        background: $color-white;
        border-radius: 6.25rem;
        padding: .25rem;
        box-shadow: 0 4px 10px 0 rgba($color-secondary, .15);
        display: flex;
        align-items: center;
        overflow: hidden;

        .alc_button {
            border-radius: 6.25rem;

            &.active {
                background: $color-primary;
                color: $color-white;
            }
        }
    }

    &--filters {
        .alc_button {            
            font-size: .75rem;
            line-height: 140%;
            font-weight: 600;
            color: $color-secondary;
            padding: 0;

            span {
                color: $color-primary;
            }
        }
    }
}